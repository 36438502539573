import { Check, Close, LockOutlined } from '@mui/icons-material';
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
  Tooltip,
  alpha,
  styled,
} from '@mui/material';
import React from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';

const StyledTextfield = styled(TextField)(({ theme }) => ({
  '&[data-disabled="true"]': {
    background: theme.palette.background.default,
  },
  [`&[data-completed="true"]`]: {
    cursor: 'pointer !important',
    '*': {
      cursor: 'pointer !important',
    },
    '& fieldset': {
      borderColor: `${alpha(theme.palette.common.black, 0.23)} !important`,
    },
  },
  [`&[data-completed="false"]`]: {
    cursor: 'pointer !important',
    '*': {
      cursor: 'pointer !important',
    },
  },
  '&:hover [data-action="delete"]': {
    opacity: 1,
  },
  '&:hover [data-action="check"]': {
    opacity: 0,
  },
  '&:hover button': {
    background: alpha(theme.palette.common.black, 0.08),
  },
  '&:hover button:hover': {
    background: alpha(theme.palette.common.black, 0.12),
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: 'relative',
  width: 28,
  height: 28,
  '& > svg': {
    position: 'absolute',
    fontSize: 20,
    transition: 'all 0.25s ease-in-out',
  },
  '[data-action="delete"]': {
    opacity: 0,
  },
  '[data-action="check"]': {
    opacity: 1,
  },
}));

interface RotraTextFieldProps {
  fieldPath: string;
  showErrorMessage?: boolean;
  maxLength?: number;
  minLength?: number;
  showMaxLengthIndicator?: boolean;
  forceErrorState?: boolean;
}

export default function RotraTextField({
  fieldPath,
  showErrorMessage = true,
  type = undefined,
  helperText,
  margin,
  inputProps,
  maxLength = Number.MAX_SAFE_INTEGER,
  minLength = 0,
  showMaxLengthIndicator,
  forceErrorState,
  ...rest
}: RotraTextFieldProps & TextFieldProps) {
  const { control } = useFormContext();

  const getHelperText = (value: string, error?: FieldError) => {
    if (
      ((showMaxLengthIndicator && !error) || error?.type === 'max') &&
      value?.length !== 0
    ) {
      return `${value?.length || 0}/${
        maxLength || 'maxLength property is undefined'
      }`;
    }

    if (error?.message) {
      return error.message;
    }

    return helperText;
  };

  return (
    <Controller
      name={fieldPath}
      control={control}
      render={({ field: { value, onChange, ...restField }, fieldState }) => {
        const customOnChangeLogic = (
          event: React.ChangeEvent<HTMLInputElement>
        ) => {
          if (type === 'number') {
            if (event.target.value.trim() === '') {
              onChange(null);
            } else if (
              event.target.value.length <= maxLength &&
              event.target.value.length >= minLength
            ) {
              onChange(event.target.value);
            }
          } else {
            onChange(event);
          }
        };
        return (
          <StyledTextfield
            data-completed={
              (rest.required && !!value && !fieldState.error) || !rest.required
            }
            data-disabled={rest.disabled ? 'true' : 'false'}
            sx={{
              marginBottom: (theme) =>
                margin === 'none' ? 0 : theme.spacing(1),
            }}
            margin={margin}
            value={value || ''}
            {...restField}
            {...rest}
            type={type}
            error={forceErrorState || fieldState.invalid}
            helperText={getHelperText(
              value,
              showErrorMessage ? fieldState.error : undefined
            )}
            FormHelperTextProps={{
              style: {
                textAlign:
                  showMaxLengthIndicator &&
                  (value?.length || 0) > 0 &&
                  (!fieldState.error || fieldState.error.type === 'max')
                    ? 'right'
                    : 'left',
              },
            }}
            onChange={customOnChangeLogic}
            fullWidth
            inputProps={{
              ...inputProps,
              autoComplete: 'new-password',
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {rest.disabled ? (
                    <Tooltip
                      title="Disabled because the form is read-only"
                      placement="top"
                      arrow
                    >
                      <LockOutlined />
                    </Tooltip>
                  ) : (
                    <StyledIconButton
                      tabIndex={-1}
                      size="small"
                      edge="end"
                      onClick={() => {
                        onChange('');
                      }}
                      sx={{
                        visibility:
                          value && !fieldState.error ? 'visible' : 'hidden',
                      }}
                    >
                      <Close data-action="delete" />
                      <Check data-action="check" />
                    </StyledIconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
        );
      }}
    />
  );
}
