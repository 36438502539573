import { yupResolver } from '@hookform/resolvers/yup';
import { Person } from '@mui/icons-material';
import { Avatar, Box, styled, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import RotraTextField from '../../../../react-hook-form/v2/RotraTextField';
import useRotraForm from '../../../../react-hook-form/v2/useRotraForm';
import { getInitials } from '../../../../utils/UtilMethods';
import { useActivateUser } from '../activateUser.state';

export const PersonalDetailsStepValidation = yup.object().shape({
  firstname: yup
    .string()
    .trim()
    .required('First name is required')
    .min(1)
    .max(35),
  lastname: yup
    .string()
    .trim()
    .required('Last name is required')
    .min(1)
    .max(35),
});

type ActivationPersonalDetailsFormProps = {
  firstname: string;
  lastname: string;
};

const NameFieldsContainer = styled(Box)(() => ({
  display: 'grid',
  width: '100%',
  gridTemplateRows: '1 1',
  height: 120,
}));

export default function PersonalDetailsStep() {
  const { state, actions } = useActivateUser();

  const methods = useRotraForm<ActivationPersonalDetailsFormProps>({
    formProps: {
      mode: 'all',
      delayError: 350,
      reValidateMode: 'onChange',
      resolver: yupResolver(PersonalDetailsStepValidation),
      defaultValues: {
        firstname: state.firstname,
        lastname: state.lastname,
      },
    },
    onChange: (value: ActivationPersonalDetailsFormProps) => {
      actions.updatePersonalDetails(value.firstname, value.lastname);
    },
  });

  const initialFirstname = methods.watch('firstname');
  const initialLastname = methods.watch('lastname');

  return (
    <>
      <Helmet>
        <title>Who are you?</title>
      </Helmet>
      <FormProvider {...methods}>
        <Typography variant="h5">
          <Person sx={{ mr: 1, verticalAlign: 'middle' }} />
          Who are you?
        </Typography>
        <br />
        <Typography variant="body1" mb={3}>
          Tell us a little about yourself so we can tailor your experience. This
          helps us provide the best possible service for you.
        </Typography>
        <NameFieldsContainer>
          <RotraTextField
            required
            placeholder={'First name'}
            size="small"
            margin="none"
            maxLength={35}
            fieldPath="firstname"
            type="string"
            label="First name"
            name="firstname"
          />
          <RotraTextField
            required
            placeholder={'Last name'}
            size="small"
            margin="none"
            maxLength={35}
            fieldPath="lastname"
            type="string"
            label="Last name"
            name="lastname"
          />
        </NameFieldsContainer>

        {(initialFirstname || initialLastname) && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 2,
              justifyContent: 'center',
              mt: 3,
            }}
          >
            <Avatar>
              {getInitials(
                `${(initialFirstname || '').trim()} ${
                  initialLastname || ''.trim()
                }`
              )}
            </Avatar>{' '}
            <Typography>
              {initialFirstname} {initialLastname}
            </Typography>
          </Box>
        )}
      </FormProvider>
    </>
  );
}
