import { TermsAndConditionsDTO } from '@rotra-air-ocean/rotranext-shared-code';
import { AccessTokenMethod } from './util';

const getTermsOfUse = async (): Promise<TermsAndConditionsDTO> => {
  const response = await fetch(
    `${process.env.VITE_MYROTRA_PUBLIC_API_URL}v3/terms-and-conditions?type=MYROTRA_TERMS_OF_USE`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  );

  if (!response.ok) {
    throw new Error('Failed to fetch terms of use');
  }

  return response.json() as Promise<TermsAndConditionsDTO>;
};

const AcceptTermsOfUse = async (
  getAccessTokenSilently: AccessTokenMethod,
  termsOfUseId: string
): Promise<void> => {
  const token = await getAccessTokenSilently();

  const response = await fetch(
    `${process.env.VITE_MYROTRA_API_URL}v3/terms-and-conditions/${termsOfUseId}/accept`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  );

  if (!response.ok) {
    throw new Error('Failed to accept terms of use');
  }
};

const TermsOfUseAPI = {
  getTermsOfUse,
  AcceptTermsOfUse,
};

export default TermsOfUseAPI;
