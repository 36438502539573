export const enum BookingStep {
  GENERAL = 'GENERAL',
  CARGO = 'CARGO',
  SHIPPER_CONSIGNEE = 'SHIPPER_CONSIGNEE',
  QUOTE = 'QUOTE',
  FINALIZE = 'FINALIZE',
}

export const BookingStepUrls: Record<BookingStep, string> = {
  [BookingStep.GENERAL]: 'general',
  [BookingStep.CARGO]: 'cargo',
  [BookingStep.SHIPPER_CONSIGNEE]: 'shipper-and-consignee',
  [BookingStep.QUOTE]: 'quote',
  [BookingStep.FINALIZE]: 'finalize',
};
