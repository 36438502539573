import { PatchUser, User } from '../../types/user/User';
import { AccessTokenMethod, asyncFetch, asyncPatch } from './util';

const getUserAsync = async (
  getAccessTokenSilently: AccessTokenMethod
): Promise<User> =>
  (await asyncFetch(getAccessTokenSilently, 'v3/user')).json();

const patchUserAsync = async (
  getAccessTokenSilently: AccessTokenMethod,
  user: PatchUser
): Promise<User> =>
  (await asyncPatch(getAccessTokenSilently, 'v3/user', user)).json();

const postUserProfilePictureAsync = async (
  getAccessTokenSilently: AccessTokenMethod,
  chosenFile: File
) => {
  const file = JSON.stringify({
    name: chosenFile.name,
    type: chosenFile.type,
  });
  const token = await getAccessTokenSilently();
  return await fetch(`${process.env.VITE_MYROTRA_API_URL}v3/users/avatar`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/octet-stream',
      file,
    },
    method: 'POST',
    body: chosenFile,
  });
};

const getActivateUserAsync = async (
  userId: string,
  activationToken: string
): Promise<{ email: string }> => {
  const res = await fetch(
    `${process.env.VITE_MYROTRA_PUBLIC_API_URL}v3/activate-user/${userId}?activation_token=${activationToken}`
  );

  if (res.ok) {
    return res.json() as Promise<{ email: string }>;
  }
  throw new Error(
    `Invalid activation token: ${res.statusText} (${res.status})`
  );
};

const postActivateUserAsync = async (
  userId: string,
  activationToken: string,
  payload: {
    firstname: string;
    lastname: string;
    password: string;
    terms_and_conditions_id?: string;
  }
): Promise<{ email: string }> => {
  const res = await fetch(
    `${process.env.VITE_MYROTRA_PUBLIC_API_URL}v3/activate-user/${userId}?activation_token=${activationToken}`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(payload),
    }
  );

  if (res.ok) {
    return res.json() as Promise<{ email: string }>;
  }
  throw new Error(
    `Invalid activation token: ${res.statusText} (${res.status})`
  );
};

const UserAPI = {
  getUserAsync,
  patchUserAsync,
  postUserProfilePictureAsync,
  getActivateUserAsync,
  postActivateUserAsync,
};

export default UserAPI;
