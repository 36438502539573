import { Client } from '../types/Client';
import { UserWithSubscription } from './api/ClientAPI';

export const isPendingUser = (userWithSubscription: UserWithSubscription) =>
  !userWithSubscription.firstname || !userWithSubscription.lastname;

export const isActiveUser = (userWithSubscription: UserWithSubscription) =>
  (userWithSubscription.firstname && userWithSubscription.lastname) !==
  undefined;

export const hasAdminUser = (client: Client) =>
  client.users.some((user) => user.subscription.role === 'admin');

export const getPendingUsers = (client: Client) =>
  client.users.filter((x) => isPendingUser(x));

export const getActiveUsers = (client: Client) =>
  client.users.filter((x) => isActiveUser(x));

export const hasDisplayName = (client?: Client) =>
  typeof client?.custom_client_fields?.display_name === 'string' &&
  isValidDisplayBame(client.custom_client_fields?.display_name.trim());

export const isValidDisplayBame = (name: string) =>
  name === '' || name.trim().length > 0;
