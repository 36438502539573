import {
  BookingDTO,
  BookingFreeInputDTO,
  EntityKeysDTO,
  PortDTO_v2,
} from '@rotra-air-ocean/rotranext-shared-code';

import { ModuleType } from '../../../types/ModuleType';
import { Booking_V2_DEPRECATED } from '../../../types/deprecated/BookingV2.0';
import { Booking_V2_2_DEPRECATED } from '../../../types/deprecated/BookingV2.2';
import { QuoteRequestAsBooking } from '../../../types/deprecated/QuoteRequest';

export const isImport = (module: ModuleType) =>
  ['AI', 'SIF', 'SIL'].includes(module);
export const isExport = (module: ModuleType) =>
  ['AE', 'SEL', 'SEF'].includes(module);

export const getImportExportFromModule = (module?: ModuleType) => {
  if (!module) {
    return;
  }
  return isImport(module) ? 'Import' : 'Export';
};

export const getServiceFromModule = (moduleType: ModuleType) => {
  switch (moduleType) {
    case 'SIF':
    case 'SEF': {
      return 'FCL';
    }
    case 'SIL':
    case 'SEL': {
      return 'LCL';
    }
    default: {
      return;
    }
  }
};

export const isFreeInputLocation = (
  location: PortDTO_v2 | BookingFreeInputDTO
): location is BookingFreeInputDTO => location._type === 'BOOKING_FREE_INPUT';

const BookingUtils = {
  isImport,
  isExport,
  getImportExportFromModule,
  getServiceFromModule,
};

export const getIdFromEntityKeys = (entityKeys: EntityKeysDTO) => {
  const { client_id, type, module, locode, id } = entityKeys;
  return `${client_id}-${type}-${locode}-${module}-${id}`;
};

export const isBookingV3 = (
  booking:
    | Booking_V2_DEPRECATED
    | Booking_V2_2_DEPRECATED
    | BookingDTO
    | QuoteRequestAsBooking
    | undefined
): booking is BookingDTO =>
  !!booking && '_api_version' in booking && booking._api_version === 3;

export const isQuoteRequestBooking = (
  booking:
    | Booking_V2_DEPRECATED
    | Booking_V2_2_DEPRECATED
    | BookingDTO
    | QuoteRequestAsBooking
    | undefined
): booking is QuoteRequestAsBooking =>
  !!booking && 'request_type' in booking && booking.request_type === 'BOOKING';

export const isBookingWithRate = (
  booking:
    | Booking_V2_DEPRECATED
    | Booking_V2_2_DEPRECATED
    | BookingDTO
    | QuoteRequestAsBooking
) => {
  if ('quotationId' in booking) {
    return true;
  } else if ('contractId' in booking) {
    return true;
  } else if ('_source' in booking) {
    return true;
  }

  return false;
};

export default BookingUtils;
