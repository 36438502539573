import { Box, Paper, styled } from '@mui/material';
import HighlightRow from './HighlightRow';

const StyledPaper = styled(Paper)(({ theme }) => ({
  borderColor: theme.palette.divider,
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: theme.spacing(0.5),
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

type PropTypes = {
  password: string;
};

export default function PasswordValidityHighlighter({ password }: PropTypes) {
  return (
    <StyledPaper
      variant="outlined"
      sx={() => ({
        width: '100%',
      })}
    >
      <Box fontWeight={700} fontSize={16}>
        Your password must contain:
      </Box>
      <HighlightRow
        password={password ?? ''}
        rule={/^.*(?=.{8,}).*$/}
        label={'At least 8 characters'}
      />
      <HighlightRow
        password={password}
        rule={/^.*((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/}
        label={'At least one lower- and uppercase'}
      />
      <HighlightRow
        password={password}
        rule={/^.*(?=.*\d).*$/}
        label={'At least one number'}
      />
      <HighlightRow
        password={password}
        rule={/^.*((?=.*[!@#$%^&*]){1}).*$/}
        label={'At least one special (!@#$%^&*) character'}
      />
    </StyledPaper>
  );
}
