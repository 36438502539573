import { Check, Close } from '@mui/icons-material';
import { useMemo } from 'react';

type PropTypes = {
  rule: RegExp;
  password: string;
  label: string;
};

export default function HighlightRow({ label, rule, password }: PropTypes) {
  const isValid = useMemo(() => rule.test(password), [rule, password]);

  return (
    <div>
      {isValid ? (
        <Check
          fontSize="small"
          sx={(th) => ({ fill: th.palette.accentGreen.A400 })}
        />
      ) : (
        <Close fontSize="small" sx={{ fill: 'red' }} />
      )}
      {label}
    </div>
  );
}
