import { createContext, useContext, useMemo, useReducer } from 'react';

import useResponsiveness from '../../../utils/useResponsiveness';

export type RotraLayoutSettingsStateType = {
  menuState: 'narrow' | 'wide';
  mobileDrawerOpen: boolean;

  environmentBox?: {
    text: string;
    color: string;
  };
};

export type RotraLayoutSettingsActionType =
  | {
      type: 'openMobileDrawer';
    }
  | {
      type: 'closeMobileDrawer';
    }
  | {
      type: 'updateMenuState';
      payload: RotraLayoutSettingsStateType['menuState'];
    }
  | {
      type: 'updateEnvironmentBox';
      payload: RotraLayoutSettingsStateType['environmentBox'];
    };

export const rotraLayoutSettingsReducer = (
  state: RotraLayoutSettingsStateType,
  action: RotraLayoutSettingsActionType
): RotraLayoutSettingsStateType => {
  switch (action.type) {
    case 'openMobileDrawer': {
      return { ...state, mobileDrawerOpen: true };
    }
    case 'closeMobileDrawer': {
      return { ...state, mobileDrawerOpen: false };
    }
    case 'updateMenuState': {
      return { ...state, menuState: action.payload };
    }
    case 'updateEnvironmentBox': {
      return { ...state, environmentBox: action.payload };
    }
  }
};

interface ActivateUserContextType {
  state: RotraLayoutSettingsStateType;
  dispatch: React.Dispatch<RotraLayoutSettingsActionType>;
}

export const RotraLayoutSettingsContext = createContext<
  ActivateUserContextType | undefined
>(undefined);

export const initialRotraLayoutSettingsState: RotraLayoutSettingsStateType = {
  menuState: 'narrow',
  mobileDrawerOpen: false,
};

type PropType = {
  children: React.ReactNode;
};

export const RotraLayoutSettingsProvider = ({ children }: PropType) => {
  const [state, dispatch] = useReducer(
    rotraLayoutSettingsReducer,
    initialRotraLayoutSettingsState
  );

  return (
    <RotraLayoutSettingsContext.Provider value={{ state, dispatch }}>
      {children}
    </RotraLayoutSettingsContext.Provider>
  );
};

const useRotraLayoutSettings = () => {
  const context = useContext(RotraLayoutSettingsContext);
  const {
    options: {
      breakpoints: { xs, sm },
    },
  } = useResponsiveness();

  if (!context) {
    throw new Error(
      'useRotraLayoutSettings must be used within a RotraLayoutSettingsProvider'
    );
  }

  const { state, dispatch } = context;

  const actions = useMemo(
    () => ({
      openMobileDrawer: () => {
        dispatch({ type: 'openMobileDrawer' });
      },
      closeMobileDrawer: () => {
        dispatch({ type: 'closeMobileDrawer' });
      },
      updateMenuState: (
        menuState: RotraLayoutSettingsStateType['menuState']
      ) => {
        dispatch({ type: 'updateMenuState', payload: menuState });
      },
      updateEnvironmentBox: (
        environmentBox: RotraLayoutSettingsStateType['environmentBox']
      ) => {
        dispatch({ type: 'updateEnvironmentBox', payload: environmentBox });
      },
    }),
    [dispatch]
  );

  const computedState = useMemo(() => {
    const isMobile = xs || sm;

    if (isMobile) {
      return {
        navWidth: 'unset',
      };
    }

    return {
      navWidth: state.menuState === 'narrow' ? 56 : 220,
    };
  }, [sm, state.menuState, xs]);

  return {
    state,
    actions,
    computedState,
  };
};
export default useRotraLayoutSettings;
