import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    fallbackLng: ['en', 'nl'], // first en then nl
    supportedLngs: ['en', 'nl'],
    debug: true,
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
    },
    pluralSeparator: '_',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    backend: {
      loadPath: '/static/locales/{{lng}}.json',
    },
  });

export default i18n;
