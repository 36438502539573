import { TermsAndConditionsDTO } from '@rotra-air-ocean/rotranext-shared-code';
import { WebAuth } from 'auth0-js';
import { createContext, useContext, useMemo, useReducer } from 'react';
import PATHS from '../../../_myrotra/paths';
import TermsOfUseAPI from '../../../utils/api/TermsOfUseAPI';
import UserAPI from '../../../utils/api/UserAPI';
import { PasswordStepValidation } from './steps/PasswordStep';
import { PersonalDetailsStepValidation } from './steps/PersonalDetailsStep';

const activateUserSteps = [
  'welcome',
  'personal-details',
  'password',
  'account-completion',
] as const;

export type ActivateUserSteps = (typeof activateUserSteps)[number];

export type ActivateUserStateType = {
  termsAndConditions?: TermsAndConditionsDTO;
  isLoadingTermsAndConditions: boolean;
  termsAndConditionsError?: string;
  acceptedTermsAndConditions?: boolean;

  activeStep: ActivateUserSteps;
  userEmail?: string;

  firstname?: string;
  lastname?: string;
  password?: string;

  submittingState?:
    | 'activating-account'
    | 'signing-in'
    | 'activating-error'
    | 'signing-in-error';
  isLoading: boolean;
  error?: string;
  loadingMessage?: string;
};

export type ActivateUserActionType =
  | {
      type: 'startLoading';
    }
  | {
      type: 'finishLoading';
      payload: {
        email: string;
      };
    }
  | {
      type: 'errorLoading';
      payload: string;
    }
  | {
      type: 'goToStep';
      payload: ActivateUserSteps;
    }
  | {
      type: 'startLoadingTermsAndConditions';
    }
  | {
      type: 'errorLoadingTermsAndConditions';
      payload: string;
    }
  | {
      type: 'finishLoadingTermsAndConditions';
      payload: TermsAndConditionsDTO;
    }
  | {
      type: 'setFirstname';
      payload: string;
    }
  | {
      type: 'setLastname';
      payload: string;
    }
  | {
      type: 'setPassword';
      payload: string;
    }
  | {
      type: 'toggleAcceptedTermsAndConditions';
    }
  | {
      type: 'startActivation';
    }
  | {
      type: 'startSigningIn';
    }
  | {
      type: 'activationError';
    }
  | {
      type: 'signingInError';
    };

export const activateUserReducer = (
  state: ActivateUserStateType,
  action: ActivateUserActionType
): ActivateUserStateType => {
  switch (action.type) {
    case 'startLoading': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'finishLoading': {
      return {
        ...state,
        isLoading: false,
        userEmail: action.payload.email,
      };
    }
    case 'errorLoading': {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }
    case 'goToStep': {
      return {
        ...state,
        activeStep: action.payload,
      };
    }
    case 'startLoadingTermsAndConditions': {
      return {
        ...state,
        isLoadingTermsAndConditions: true,
      };
    }
    case 'finishLoadingTermsAndConditions': {
      return {
        ...state,
        isLoadingTermsAndConditions: false,
        termsAndConditions: action.payload,
      };
    }
    case 'errorLoadingTermsAndConditions': {
      return {
        ...state,
        isLoadingTermsAndConditions: false,
        termsAndConditionsError: action.payload,
      };
    }
    case 'setFirstname': {
      return {
        ...state,
        firstname: action.payload,
      };
    }
    case 'setLastname': {
      return {
        ...state,
        lastname: action.payload,
      };
    }
    case 'setPassword': {
      return {
        ...state,
        password: action.payload,
      };
    }
    case 'toggleAcceptedTermsAndConditions': {
      return {
        ...state,
        acceptedTermsAndConditions: !state.acceptedTermsAndConditions,
      };
    }
    case 'startActivation': {
      return {
        ...state,
        submittingState: 'activating-account',
      };
    }
    case 'startSigningIn': {
      return {
        ...state,
        submittingState: 'signing-in',
      };
    }

    default: {
      return state;
    }
  }
};

export const initialActivateUserState: ActivateUserStateType = {
  activeStep: 'welcome',
  isLoading: true,

  isLoadingTermsAndConditions: false,
};

interface ActivateUserContextType {
  state: ActivateUserStateType;
  dispatch: React.Dispatch<ActivateUserActionType>;
}

export const ShareShipmentContext = createContext<
  ActivateUserContextType | undefined
>(undefined);

type PropType = {
  children: React.ReactNode;
};
export const ActivateUserProvider = ({ children }: PropType) => {
  const [state, dispatch] = useReducer(
    activateUserReducer,
    initialActivateUserState
  );

  return (
    <ShareShipmentContext.Provider value={{ state, dispatch }}>
      {children}
    </ShareShipmentContext.Provider>
  );
};

const auth = new WebAuth({
  domain: process.env.VITE_AUTH0_DOMAIN || 'unknown',
  clientID: process.env.VITE_AUTH0_CLIENTID || 'unknown',
});

export const useActivateUser = () => {
  const context = useContext(ShareShipmentContext);

  if (!context) {
    throw new Error(
      'useActivateUser must be used within a ActivateUserProvider'
    );
  }

  const { state, dispatch } = context;

  const actions = useMemo(
    () => ({
      validateActivationToken: (userId: string, activationToken: string) => {
        dispatch({
          type: 'startLoading',
        });

        void UserAPI.getActivateUserAsync(userId, activationToken)
          .then((user) => {
            setTimeout(() => {
              dispatch({
                type: 'finishLoading',
                payload: {
                  email: user.email,
                },
              });
            }, 750);
          })
          .catch(() => {
            dispatch({
              type: 'errorLoading',
              payload: 'Invalid activation token',
            });
          });
      },
      nextStep: (currentStep: ActivateUserSteps) => {
        const nextStepIndex = activateUserSteps.indexOf(currentStep) + 1;
        dispatch({
          type: 'goToStep',
          payload: activateUserSteps[nextStepIndex],
        });
      },
      previousStep: (currentStep: ActivateUserSteps) => {
        const nextStepIndex = activateUserSteps.indexOf(currentStep) - 1;
        dispatch({
          type: 'goToStep',
          payload: activateUserSteps[nextStepIndex],
        });
      },
      loadTermsAndConditions: () => {
        dispatch({
          type: 'startLoadingTermsAndConditions',
        });
        void TermsOfUseAPI.getTermsOfUse()
          .then((result) => {
            dispatch({
              type: 'finishLoadingTermsAndConditions',
              payload: result,
            });
          })
          .catch(() => {
            dispatch({
              type: 'errorLoadingTermsAndConditions',
              payload: 'Failed to load terms and conditions',
            });
          });
      },
      updatePersonalDetails: (firstname: string, lastname: string) => {
        dispatch({
          type: 'setFirstname',
          payload: firstname,
        });
        dispatch({
          type: 'setLastname',
          payload: lastname,
        });
      },
      updatePassword: (password: string) => {
        dispatch({
          type: 'setPassword',
          payload: password,
        });
      },
      toggleAcceptedTermsAndConditions: () => {
        dispatch({
          type: 'toggleAcceptedTermsAndConditions',
        });
      },
    }),
    [dispatch]
  );

  const computedActions = useMemo(
    () => ({
      submit: (userId: string, activationToken: string) => {
        if (state.firstname && state.lastname && state.password) {
          dispatch({
            type: 'startActivation',
          });

          UserAPI.postActivateUserAsync(userId, activationToken, {
            firstname: state.firstname,
            lastname: state.lastname,
            password: state.password,
            terms_and_conditions_id: state.termsAndConditions?._key,
          })
            .then(() => {
              dispatch({
                type: 'startSigningIn',
              });
              auth.login(
                {
                  realm: 'Username-Password-Authentication',
                  email: state.userEmail,
                  password: state.password as string,
                  responseType: 'token id_token',
                  audience: process.env.VITE_AUTH0_AUDIENCE,
                  scope: 'openid profile email',
                  redirectUri: `${window.location.origin}${PATHS.activate_user.finish}`,
                },
                () => {
                  dispatch({
                    type: 'signingInError',
                  });
                }
              );
            })
            .catch(() => {
              dispatch({
                type: 'activationError',
              });
            });
        }
      },
    }),
    [
      dispatch,
      state.firstname,
      state.lastname,
      state.password,
      state.termsAndConditions?._key,
      state.userEmail,
    ]
  );

  const isCurrentStepCompleted = useMemo(() => {
    switch (state.activeStep) {
      case 'welcome': {
        return true;
      }
      case 'personal-details': {
        return PersonalDetailsStepValidation.isValidSync(state);
      }
      case 'password': {
        return PasswordStepValidation.isValidSync(state);
      }
      case 'account-completion': {
        return state.acceptedTermsAndConditions === true;
      }
    }
  }, [state]);

  return {
    state,
    actions,
    computedState: {
      isCurrentStepCompleted,
    },
    computedActions,
    activateUserSteps,
  };
};
