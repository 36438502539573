import {
  BookingDTO,
  EntityTypeObject,
  Shipment,
  TransportAir,
  TransportSea,
} from '@rotra-air-ocean/rotranext-shared-code';
import Hex from 'crypto-js/enc-hex';
import SHA256 from 'crypto-js/sha256';
import { isQuoteRequestBooking } from '../components/pages/bookings/BookingUtils';

import { Contract_v3_or_v3_1 } from '../types/ContractV3.1';
import { ModuleType } from '../types/ModuleType';
import type { EntityKeysDTO, QuotationDTO } from '../types/QuotationDTO';
import {
  AddressModel_DEPRECATED,
  AddressModelSingleContact_DEPRECATED,
} from '../types/deprecated/Address';
import { Booking_V2_DEPRECATED } from '../types/deprecated/BookingV2.0';
import { Booking_V2_2_DEPRECATED } from '../types/deprecated/BookingV2.2';
import {
  QuoteRequest_DEPRECATED,
  QuoteRequestAsBooking,
} from '../types/deprecated/QuoteRequest';
import { isActiveUser } from './ClientUtils';
import { FreightMethods } from './FilterOptions';
import { UserWithSubscription } from './api/ClientAPI';

export function enforceNumericValue(value: string | number | undefined | null) {
  if (typeof value === 'number') {
    return value;
  }
  if (typeof value === 'string') {
    return Number(value);
  }
  return 0;
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function convertToCamelCaseWithSpaces(string: string) {
  // Split the string by underscores
  const words = string.toLowerCase().split('_');

  // Capitalize the first letter of each word
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }

  // Join the words with a space and return
  return words.join(' ');
}

export const isObjectEmpty = (objectName: object) => {
  return Object.keys(objectName).length === 0;
};

export function enforceOptionalNumericValue(
  value: string | number | undefined | null
) {
  if (value === null || value === undefined) {
    return value;
  }
  return enforceNumericValue(value);
}

export const isImport = (module?: ModuleType) =>
  !!module && ['AI', 'SIF', 'SIL'].includes(module);
export const isExport = (module?: ModuleType) =>
  !!module && ['AE', 'SEL', 'SEF'].includes(module);

export type FreightType = 'air' | 'sea';

export const getFreightFromModule = (module?: ModuleType): FreightType =>
  module?.startsWith('S') ? 'sea' : 'air';

export type ModalityType = 'AIR' | 'SEA';

export const getModalityFromModule = (module: ModuleType): ModalityType =>
  module.startsWith('S') ? 'SEA' : 'AIR';

export const getAirLclFclFromModule = (
  moduleName: ModuleType
): FreightMethods => {
  switch (moduleName) {
    case 'AI':
    case 'AE': {
      return 'AIR';
    }
    case 'SIF':
    case 'SEF': {
      return 'FCL';
    }
    case 'SIL':
    case 'SEL': {
      return 'LCL';
    }
    default: {
      return moduleName;
    }
  }
};

export const getDirectionsWithCargoTypeLabel = (
  moduleName: ModuleType
): string => {
  switch (moduleName) {
    case 'AE': {
      return 'Export';
    }
    case 'AI': {
      return 'Import';
    }
    case 'SEF': {
      return 'Export FCL';
    }
    case 'SIF': {
      return 'Import FCL';
    }
    case 'SEL': {
      return 'Export LCL';
    }
    case 'SIL': {
      return 'Import LCL';
    }
    default: {
      return moduleName;
    }
  }
};

export const getImportExportFromModule = (module?: ModuleType) =>
  isImport(module) ? 'Import' : 'Export';

export const getServiceFromModule = (module?: ModuleType) => {
  if (module === 'SIF' || module === 'SEF') {
    return 'FCL';
  }
  if (module === 'SIL' || module === 'SEL') {
    return 'LCL';
  }
  return;
};

export const isCompanyInfoComplete = (
  contact: AddressModelSingleContact_DEPRECATED
) =>
  !!(
    contact.company?.company &&
    contact.company?.street_address &&
    contact.company?.country &&
    contact.company?.postal_code &&
    contact.company?.city
  );

export const isContactInfoComplete = (
  contact: AddressModelSingleContact_DEPRECATED
) =>
  !!(
    isCompanyInfoComplete(contact) &&
    contact.contact?.person &&
    contact.contact?.email &&
    contact.contact?.phone
  );

export const round = (number: number, decimals = 0) => {
  const exp = 10 ** decimals;
  return number === 0 ? 0 : (Math.round(number * exp) / exp).toFixed(decimals);
};

export const roundWeight = (number: number) => round(number, 2);

export const roundVolume = (number: number) => round(number, 3);

export const hashSha256 = (msg: string) => SHA256(msg).toString(Hex);

export const hashKey = <T,>(hash: T | undefined) => {
  if (!hash) {
    return '{}';
  }
  return hashSha256(
    Object.keys(hash)
      .map((key) => JSON.stringify(hash[key as keyof T]))
      .join('-')
  );
};

export type EmptyLocation = {
  name: string;
  geo: {
    lat: number;
    lng: number;
  } | null;
  country: {
    name: string;
    code: string;
  } | null;
};

export const emptyLocationObject = (): EmptyLocation => ({
  name: '',
  geo: null,
  country: null,
});

export const emptyContainerObject = () => ({
  quantity: 1,
  type: '',
});

/**
 * Returns the index of the last element in the array where predicate is true, and -1
 * otherwise.
 * @param array The source array to search in
 * @param predicate find calls predicate once for each element of the array, in descending
 * order, until it finds one where predicate returns true. If such an element is found,
 * findLastIndex immediately returns that element index. Otherwise, findLastIndex returns -1.
 */
export function findLastIndex<T>(
  array: T[],
  predicate: (value: T, index: number, obj: T[]) => boolean
): number {
  let l = array.length;
  while (l !== -1) {
    if (predicate(array[l], l, array)) {
      return l;
    }

    l -= 1;
  }
  return -1;
}

export const truncate = (
  fullStr: string,
  strLen: number,
  separator = '...'
) => {
  if (fullStr.length <= strLen) {
    return fullStr;
  }

  const separatorValue = separator || '...';

  const sepLen = separatorValue.length;
  const charsToShow = strLen - sepLen;
  const frontChars = Math.ceil(charsToShow / 2);
  const backChars = Math.floor(charsToShow / 2);

  return (
    fullStr.slice(0, Math.max(0, frontChars)) +
    separator +
    fullStr.slice(Math.max(0, fullStr.length - backChars))
  );
};

export const multiDimensionalUnique = <T,>(items: T[], keys: (keyof T)[]) => {
  const itemsFound: { [key: string]: T } = {};
  for (const item of items) {
    const projection = keys
      .map((key) => JSON.stringify(item[key]))
      .join('-<>-');
    itemsFound[projection] = item; // we get the last occurrence of the projection
  }
  return Object.values(itemsFound);
};

type NonEmptyArray<T> = [T, ...T[]];

export const isNonEmptyArray = <T,>(
  array: T[] | undefined
): array is NonEmptyArray<T> => {
  return Array.isArray(array) && array.length > 0;
};

/**
 * typings voor Object.entries. Hierbij de uitleg en beperkingen uit https://stackoverflow.com/a/60142095:
 *
 * Why Typescript doesn't give a stronger type to Object.entries:
 * When you have a type like type Obj = {a: number, b: string, c: number}, it's only guaranteed that a value has
 * those properties; it is not guaranteed that the value does not also have other properties. For example, the
 * value {a: 1, b: 'foo', c: 2, d: false} is assignable to the type Obj (excess property checking for object literals aside).
 *
 * In this case Object.entries would return an array containing the element ['d', false]. The type
 * Entries<Obj> says this cannot happen, but in fact it can happen; so Entries<T> is not a sound return type for
 * Object.entries in general. You should only use the above solution with Object.entries when you yourself know that the
 * values will have no excess properties; Typescript won't check this for you.
 */
export type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export const toSnakeCase = (str: string): string => {
  const strArr = str.split(' ');
  const snakeArr = strArr.reduce((acc, val) => {
    return [...acc, val.toLowerCase()];
  }, [] as string[]);
  return snakeArr.join('_');
};

export const toKebabCase = (str: string): string =>
  str
    .replaceAll(/([a-z])([A-Z])/g, '$1-$2')
    .replaceAll(/[\s_]+/g, '-')
    .toLowerCase();

/**
 * truncates the middle of a string
 * @param text
 * @param maxLength
 * @returns
 */
export const truncateMiddle = ({
  text,
  maxLength,
  separator = '…',
}: {
  text: string;
  maxLength: number;
  separator?: string;
}) => {
  if (text.length <= maxLength) {
    return text;
  }
  const halfLength = Math.floor(maxLength / 2);
  return `${text.slice(0, halfLength)}${separator}${text.slice(-halfLength)}`;
};

export const truncateClientName = (name: string, maxLength = 55) =>
  truncateMiddle({
    text: name,
    maxLength: maxLength,
  });

export const getInitials = (name: string) => {
  return name
    .split(' ')
    .slice(0, 2)
    .map((val) => `${val}`[0])
    .join('')
    .toUpperCase();
};

export const getAvatarText = (user: UserWithSubscription) => {
  return isActiveUser(user)
    ? getInitials(user.firstname?.trim() + ' ' + user.lastname?.trim())
    : getInitials(user.email.trim());
};

export type AllEntities =
  | Shipment
  | QuoteRequest_DEPRECATED
  | QuotationDTO
  | Contract_v3_or_v3_1
  | Booking_V2_DEPRECATED
  | Booking_V2_2_DEPRECATED
  | BookingDTO
  | QuoteRequestAsBooking
  | AddressModel_DEPRECATED;

export const isQuoteRequest = (
  entity?: AllEntities
): entity is QuoteRequest_DEPRECATED =>
  entity !== undefined && 'type' in entity && entity.type === 'QUOTE_REQUEST';

export const isQuotation = (entity?: AllEntities): entity is QuotationDTO =>
  entity !== undefined && '_type' in entity && entity._type === 'QUOTATION';

export const isShipment = (entity?: AllEntities): entity is Shipment =>
  entity !== undefined && '_type' in entity && entity._type === 'SHIPMENT';

export const isContract = (
  entity?: AllEntities
): entity is Contract_v3_or_v3_1 =>
  entity !== undefined && '_type' in entity && entity._type === 'CONTRACT';

export const isAddress = (
  entity?: AllEntities
): entity is AddressModel_DEPRECATED =>
  entity !== undefined && 'type' in entity && entity.type === 'ADDRESS';

export const isBooking = (
  entity?: AllEntities
): entity is
  | Booking_V2_DEPRECATED
  | Booking_V2_2_DEPRECATED
  | BookingDTO
  | QuoteRequestAsBooking =>
  (entity && '_type' in entity && entity._type === 'BOOKING') ||
  (entity && 'type' in entity && entity.type === 'BOOKING') ||
  (isQuoteRequest(entity) &&
    isQuoteRequestBooking(entity as unknown as QuoteRequestAsBooking));

export const getEntityLink = (
  entity?: AllEntities,
  returnPage?: string
): string | undefined => {
  const returnPageFull = returnPage ? `?returnPage=${returnPage}` : '';
  if (isQuoteRequest(entity)) {
    return `/quotes/quote-requests/${entity.id}${returnPageFull}`;
  }

  if (isQuotation(entity)) {
    return `/quotes/spot-rates/${entity.id}${returnPageFull}`;
  }

  if (isShipment(entity)) {
    return `/shipments/${entity.id}${returnPageFull}`;
  }
  if (isContract(entity)) {
    return `/contracts/${entity.id}${returnPageFull}`;
  }

  if (isAddress(entity)) {
    return `/addressbook/${entity.id}${returnPageFull}`;
  }

  if (isBooking(entity)) {
    return `/bookings/${entity.id}${returnPageFull}`;
  }

  return undefined;
};

export const sanitizeFileName = (filename: string) =>
  filename.replaceAll(/[^\d.A-Za-z]/g, '-').toLowerCase();

export const constructIdFromEntityKeys = (entityKeys: EntityKeysDTO) => {
  const { id, client_id, locode, module, type } = entityKeys;
  return `${client_id}-${type}-${locode}-${module}-${id}`;
};

export const getTransportNameForShipment = (
  transport?: TransportAir | TransportSea
) => {
  if (transport?._type === EntityTypeObject.TRANSPORT_AIR) {
    return transport.flight_number ?? null;
  } else if (transport?._type === EntityTypeObject.TRANSPORT_SEA) {
    return transport.vessel ?? null;
  } else {
    return null;
  }
};

export const transportNamesForShipment = (
  shipment: Shipment | undefined
): string[] => {
  if (!shipment?.itinerary?.route_segments) {
    return [];
  }

  return shipment.itinerary.route_segments
    .map((item) =>
      'transport' in item
        ? getTransportNameForShipment(item.transport)
        : undefined
    )
    .filter(Boolean) as string[];
};
