import { AppState, Auth0Provider } from '@auth0/auth0-react';
import 'moment/dist/locale/en-gb';
import { ComponentType, Suspense, useCallback } from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import MainComponentLazy from './MainComponentLazy';
import { ActivateUserProvider } from './components/pages/activate_user/activateUser.state';
import BlockedUserWrapper from './components/shared/auth0/BlockedUserWrapper';
import Loading from './components/shared/components/Loading';
import { RotraLayoutSettingsProvider } from './components/shared/layouts/useRotraLayoutSettings';
import './index.css';
import { lazyReactNaiveRetry } from './utils/LazyUtils';

const ShipmentDetailPublicPageLazy = lazyReactNaiveRetry(
  () =>
    import('./components/pages/shipment_detail_public/ShipmentDetailPublicPage')
);

const ActivateUserPageLazy = lazyReactNaiveRetry(
  () => import('./components/pages/activate_user/ActivateUserPage')
);

const FinalActivateUserStep = lazyReactNaiveRetry(
  () => import('./components/pages/activate_user/conditional_steps/IntroStep')
);

const LazyWrapper = ({
  component: Component,
}: {
  component: ComponentType;
}) => {
  return (
    <Suspense fallback={<Loading />}>
      <Component />
    </Suspense>
  );
};

const App = () => {
  const location = useLocation();
  const history = useHistory();

  const onRedirectCallback = useCallback(
    (appState?: AppState) => {
      if (appState?.returnTo && appState?.returnTo !== location.pathname) {
        location.pathname = appState.returnTo;
        history.replace(appState.returnTo);
      }
    },
    [history, location]
  );

  return (
    <RotraLayoutSettingsProvider>
      <Auth0Provider
        cacheLocation={navigator.webdriver ? 'localstorage' : undefined}
        domain={process.env.VITE_AUTH0_DOMAIN || ''}
        clientId={process.env.VITE_AUTH0_CLIENTID || ''}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: process.env.VITE_AUTH0_AUDIENCE,
          scope: 'openid profile email',
        }}
        onRedirectCallback={onRedirectCallback}
      >
        <Switch>
          <Route
            path="/shared/shipment/:guid"
            render={() => (
              <LazyWrapper component={ShipmentDetailPublicPageLazy} />
            )}
            exact
          />
          <Route
            path="/activate-user/:userId"
            render={() => (
              <ActivateUserProvider>
                <LazyWrapper component={ActivateUserPageLazy} />
              </ActivateUserProvider>
            )}
            exact
          />
          <Route
            path="/finished"
            render={() => <LazyWrapper component={FinalActivateUserStep} />}
            exact
          />
          <BlockedUserWrapper>
            <MainComponentLazy />
          </BlockedUserWrapper>
        </Switch>
      </Auth0Provider>
    </RotraLayoutSettingsProvider>
  );
};

export default App;
