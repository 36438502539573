import { useEffect } from 'react';
import {
  EventType,
  FieldPath,
  FieldValues,
  useForm,
  UseFormProps,
} from 'react-hook-form';

interface useRotraFormProps<Type extends FieldValues> {
  formProps: UseFormProps<Type>;
  onChange?: (
    value: Type,
    extraInfo: { name?: FieldPath<Type>; type?: EventType }
  ) => void;
  onChangeDelay?: number;
}

type UseFormType = ReturnType<typeof useForm>;
type Subscription = ReturnType<UseFormType['watch']>;

const setTriggerableTimeout = (handler: () => void, delay: number) => {
  const id = setTimeout(handler, delay),
    clear = clearTimeout.bind(null, id);
  return {
    id,
    clear,
    flush: () => {
      clear();
      handler();
    },
  };
};

export default function useRotraForm<Type extends FieldValues>({
  formProps,
  onChangeDelay = 350,
  onChange,
}: useRotraFormProps<Type>) {
  const methods = useForm(formProps);

  // FIXME this is a hacky way to do this
  // ... and we should really redo this when we implement the single-channel booking form
  useEffect(() => {
    let flushOnChange: () => void;
    let clearOnChange: () => void;
    let subscription: Subscription;
    if (onChange) {
      subscription = methods.watch((value: unknown, { name, type }) => {
        if (clearOnChange) clearOnChange();

        const { flush, clear } = setTriggerableTimeout(
          () => onChange(value as Type, { name, type }),
          onChangeDelay
        );

        clearOnChange = clear;
        flushOnChange = flush;
      });
    }
    return () => {
      // FIXME this hook gets unmounted ALOT, and this is causing a memory leak among other strange behavior probably
      if (subscription) subscription.unsubscribe();
      if (flushOnChange) flushOnChange();
    };
  }, [methods, onChange, onChangeDelay]);

  return methods;
}
