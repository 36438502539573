import { GetTokenSilentlyOptions } from '@auth0/auth0-react';

export type AccessTokenMethod = (
  options?: GetTokenSilentlyOptions | undefined
) => Promise<string>;

export const asyncFetch = async <T>(
  getAccessTokenSilently: AccessTokenMethod,
  apiAction: string,
  options?: T
) => {
  const token = await getAccessTokenSilently();
  const _options = {
    accept: 'application/json',
    contentType: 'application/json',
    ...options,
  };
  return fetch(process.env.VITE_MYROTRA_API_URL + apiAction, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: _options?.accept,
      'Content-Type': _options?.contentType,
    },
  }).then((response) => {
    if (!response.ok) {
      throw new Error(
        `Request error: ${response.status} ${response.statusText}`
      );
    }
    return response;
  });
};

export const asyncPost = async <T>(
  getAccessTokenSilently: AccessTokenMethod,
  apiAction: string,
  data: T
) => {
  const token = await getAccessTokenSilently();
  return fetch(process.env.VITE_MYROTRA_API_URL + apiAction, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(data),
  }).then((response) => {
    if (!response.ok) {
      throw new Error(
        `Request error: ${response.status} ${response.statusText}`
      );
    }
    return response;
  });
};

export const asyncDelete = async (
  getAccessTokenSilently: AccessTokenMethod,
  apiAction: string,
  data?: object
) => {
  const token = await getAccessTokenSilently();
  return fetch(process.env.VITE_MYROTRA_API_URL + apiAction, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
    body: data ? JSON.stringify(data) : null,
  }).then((response) => {
    if (!response.ok) {
      throw new Error(
        `Request error: ${response.status} ${response.statusText}`
      );
    }
    return response;
  });
};

export const asyncPut = async <T>(
  getAccessTokenSilently: AccessTokenMethod,
  apiAction: string,
  data?: T
) => {
  const token = await getAccessTokenSilently();
  return fetch(process.env.VITE_MYROTRA_API_URL + apiAction, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify(data),
  }).then((response) => {
    if (!response.ok) {
      throw new Error(
        `Request error: ${response.status} ${response.statusText}`
      );
    }
    return response;
  });
};

export const asyncPatch = async <T>(
  getAccessTokenSilently: AccessTokenMethod,
  apiAction: string,
  data: T
) => {
  const token = await getAccessTokenSilently();
  return fetch(process.env.VITE_MYROTRA_API_URL + apiAction, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
    body: JSON.stringify(data),
  }).then((response) => {
    if (!response.ok) {
      throw new Error(
        `Request error: ${response.status} ${response.statusText}`
      );
    }
    return response;
  });
};

export const genericErrorMessage =
  'An unexpected error occured. Please try again later or contact our support team via our Support tool in the bottom left corner of this screen.';
