import { yupResolver } from '@hookform/resolvers/yup';
import { Shield, Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import RotraTextField from '../../../../react-hook-form/v2/RotraTextField';
import useRotraForm from '../../../../react-hook-form/v2/useRotraForm';
import PasswordValidityHighlighter from '../PasswordValidityHighlighter';
import { useActivateUser } from '../activateUser.state';

type ActivationPasswordFormProps = {
  password: string;
};

export const PasswordStepValidation = yup.object().shape({
  //at least 8 characters including at least 3 of the following 4 types of characters:
  // a lower-case letter, an upper-case letter, a number, a special character (such as !@#$%^&*).
  password: yup
    .string()
    .trim()
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      'Password must contain at least 8 characters, one uppercase, one number and one special (!@#$%^&*) character.'
    )
    .required('Password is required'),
});

export default function PasswordStep() {
  const { state, actions } = useActivateUser();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const methods = useRotraForm<ActivationPasswordFormProps>({
    formProps: {
      mode: 'all',
      delayError: 350,
      reValidateMode: 'onChange',
      resolver: yupResolver(PasswordStepValidation),
      defaultValues: {
        password: state.password,
      },
    },
    onChange: (value: ActivationPasswordFormProps) => {
      actions.updatePassword(value.password);
    },
  });

  const watchPassword = methods.watch('password');

  useEffect(() => {
    // methods.setValue('password', password?.password ?? '');

    if (methods.getValues().password) {
      methods.trigger('password').catch((error) => {
        throw error;
      });
    }
  }, [methods]);

  return (
    <>
      <Helmet>
        <title>Secure your account</title>
      </Helmet>

      <FormProvider {...methods}>
        <Typography variant="h5">
          <Shield sx={{ mr: 1, verticalAlign: 'middle' }} />
          Secure your account
        </Typography>
        <br />
        <Typography variant="body1" mb={3}>
          Protect your account by creating a strong and secure password. This
          keeps your information safe and ensures only you have access.
        </Typography>
        <RotraTextField
          required
          size="small"
          label="Password"
          variant="outlined"
          fieldPath="password"
          type={showPassword ? 'text' : 'password'}
          name="myrotra-password"
          showErrorMessage={false}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <PasswordValidityHighlighter password={watchPassword} />
      </FormProvider>
    </>
  );
}
