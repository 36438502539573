import {
  BookingStep,
  BookingStepUrls,
} from '../components/pages/single_channel_booking/BookingStep';

const PATHS = {
  dashboard: {
    root: '/',
  },
  activate_user: {
    finish: `/finished`,
  },
  bookings: {
    root: `/bookings`,
    details: (id: string) => `/bookings/${id}`,
  },
  booking: {
    root: `/booking`,
  },
  shipments: {
    root: `/shipments`,
    details: (id: string) => `/shipments/${id}`,
  },
  contracts: {
    root: `/contracts`,
    details: (id: string) => `/contracts/${id}`,
  },
  quotations: {
    root: `/quotes/spot-rates`,
    details: (id: string) => `/quotes/spot-rates/${id}`,
  },
  quote_requests: {
    root: `/quotes/quote-requests`,
    new_quote: '/quotes/quote-requests/new',
    details: (id: string) => `/quotes/quote-requests/${id}`,
    new_booking: '/bookings/requests/new',
  },
  booking_1_channel: {
    root: '/bookings/new',
    step: (step: BookingStep = BookingStep.GENERAL) =>
      `/bookings/new/step/${BookingStepUrls[step]}`,
  },
  release_notes: {
    root: '/whats-new',
    details: (id: string) => `/whats-new/${id}`,
  },
  addressbook: {
    root: '/addressbook',
    details: (id: string) => `/addressbook/${id}`,
  },
  messaging: {
    root: '/messages',
  },
  tasks: {
    root: '/tasks',
  },
  terms_of_use: {
    root: '/terms-of-use',
  },
};

export default PATHS;
